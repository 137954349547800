<template>
  <div class="ratings">
    <v-card>
      <v-card-text>
        <v-alert
          v-if="numberOfUnansweredReviews > 0"
          dense
          outlined
          type="error"
        >
          Há avaliações sem resposta!
        </v-alert>
        <div v-if="!totalRows" class="no-ratings">
          <h5>Ainda não há avaliações para sua empresa</h5>
        </div>
        <div v-else class="has-ratings">
          <v-row class="mt-4">
            <v-col>
              <h2>Avaliação geral da empresa</h2>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col md="6">
              <v-row class="mt-3">
                <v-col>
                  <v-rating
                    hover
                    length="5"
                    size="32"
                    v-model="companyRatingValue"
                    half-increments
                    readonly
                  ></v-rating>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col class="additional-information">
                  <p>{{ totalRows }} avaliações nos últimos três meseses</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <hr />

          <v-row class="mt-4">
            <v-col>
              <h2>Comentários e avaliações</h2>
            </v-col>
          </v-row>

          <!-- percorre os comentários e exibe -->
          <v-row
            class="mt-2 mb-2"
            v-bind:key="rating.id"
            v-for="rating in ratings"
          >
            <v-col>
              <v-divider></v-divider>
              <v-row no-gutters class="mt-2">
                <v-col
                  ><h3>{{ getVisitorName(rating.Visitor.User) }}</h3></v-col
                >
              </v-row>

              <v-row no-gutters>
                <v-col class="rating-date">{{
                  formatRatingCreatedAt(rating.createdAt)
                }}</v-col>
              </v-row>

              <v-row no-gutters class="my-2">
                <v-col
                  ><h4>{{ rating.title }}</h4></v-col
                >
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-rating
                    hover
                    length="5"
                    size="15"
                    v-model="rating.ratingValue"
                    readonly
                  ></v-rating>
                </v-col>
              </v-row>

              <v-row no-gutters class="my-3" v-if="rating.comment">
                <v-col md="9">
                  <i class="fa fa-quote-left" aria-hidden="true"></i>
                  {{ rating.comment }}
                  <i class="fa fa-quote-right" aria-hidden="true"></i>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="company-answer"
                  md="9"
                  :key="answer.id"
                  v-for="answer in rating.RatingAnswers"
                >
                  <p>
                    <b>Resposta</b>
                    <br />
                    {{ answer.comment }}
                  </p>
                </v-col>
                <v-col
                  v-if="rating.RatingAnswers.length < 1"
                  class="insert-company-answer"
                  md="9"
                >
                  <v-row>
                    <v-col>
                      <v-textarea
                        outlined
                        rows="2"
                        id="textarea-small"
                        v-model="rating.companyAnswerToSave"
                        label="Insira sua resposta"
                        small
                        counter="254"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn small color="success" @click="saveAnswer(rating)">
                        <v-icon left>mdi-check</v-icon> Responder
                      </v-btn>
                      <v-btn
                        small
                        color="error"
                        class="ml-2"
                        @click.stop="openReportAnswer(rating)"
                      >
                        <v-icon left>mdi-cancel</v-icon> Denunciar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-if="globalPageNumber < totalRows / resultsPerPage">
            <v-col class="d-flex justify-center align-center">
              <v-btn small text @click="nextPage()"> Carregar mais </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Carregando...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="reportAnswerDialog"
        hide-overlay
        persistent
        width="450"
      >
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>Denunciar esta avaliação</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="reportAnswerDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col>
                <v-alert dense outlined type="error">
                  Ao denunciar uma avaliação, o admin do sistema irá optar por
                  excluí-la ou manter. Essa ação é irreversível.
                </v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  color="error"
                  rows="2"
                  v-model="reportData.reason"
                  label="Informe o motivo da sua denúncia:"
                  small
                  counter="254"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn small color="error" @click="reportAnswer()">
              <v-icon left>mdi-check</v-icon> Enviar denúncia
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="successDialog" persistent max-width="450">
        <v-card>
          <v-card-text>
            <div class="text-center pa-4">
              <v-icon large color="success">mdi-check</v-icon>
            </div>

            <div class="text-center pa-3">
              <h3>Operação realizada com sucesso</h3>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeSuccessDialog">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import errorHandler from "@/helpers/error_handler";

export default {
  name: "Ratings",
  computed: {
    ...mapState("user", ["userData"]),
  },
  data() {
    return {
      loading: false,
      reportAnswerDialog: false,
      successDialog: false,
      haveRatings: true,
      resultsPerPage: 10,
      totalRows: 0,
      numberOfUnansweredReviews: 0,
      globalPageNumber: 1,
      companyRatingValue: 0,
      reportData: {
        reason: null,
        ratingId: null,
      },
      ratings: [],
    };
  },
  methods: {
    nextPage() {
      this.globalPageNumber += 1;

      this.loadRatings(this.globalPageNumber);
    },
    async loadRatings(page) {
      try {
        let currentPage = page ? page : 1;

        let params = {
          pagination: 1,
          resultsPerPage: this.resultsPerPage,
          page: currentPage,
          reported: 0,
        };

        const response = await this.$axios.get(
          `/companies/${this.userData.advertiser.Companies[0].id}/ratings`,
          {
            params,
          }
        );

        const data = { ...response.data };

        this.setRatings(data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setRatings(data) {
      const copyOfData = { ...data };
      this.totalRows = copyOfData.count;

      this.ratings = [...this.ratings, ...copyOfData.rows];
    },
    getVisitorName(user) {
      return `${user.firstName} ${user.lastName}`;
    },
    formatRatingCreatedAt(createdAt) {
      return `${this.$moment(createdAt).format("DD/MM/YYYY [ás] HH:mm")}`;
    },
    async confirmModal(title, msg, options) {
      return await this.$root.$confirm(title, msg, options);
    },
    async saveAnswer(rating) {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Confirma envio de resposta para esta avaliação?",
            {
              color: "primary",
            }
          ))
        )
          return;

        this.loading = true;

        const res = await this.$axios.post(
          `/customers/companies/ratings/${rating.id}/answers`,
          {
            companyAnswer: rating.companyAnswerToSave,
          }
        );

        this.loadPendingRatings();

        this.insertCompanyAnswerInArray(res.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    insertCompanyAnswerInArray(data) {
      const copyOfData = { ...data };

      const index = this.ratings.findIndex(
        (r) => r.id === parseInt(copyOfData.RatingId)
      );

      if (index !== -1) {
        this.ratings[index].RatingAnswers.push(copyOfData);
      } else {
        this.ratings = [];
        this.loadRatings();
      }

      const ratingsWithoutAnswer = this.ratings.filter(
        (r) => r.RatingAnswers.length === 0
      );

      this.numberOfUnansweredReviews = ratingsWithoutAnswer.length;
    },
    async loadPendingRatings() {
      try {
        this.loading = true;

        let params = {
          reported: 0,
        };

        const response = await this.$axios.get(
          `/customers/companies/${this.userData.advertiser.Companies[0].id}/pending-ratings`,
          {
            params,
          }
        );

        const data = { ...response.data };

        if (data.ratingsWithoutAnswer && data.ratingsWithoutAnswer.length === 0)
          this.$emit("no-pending-ratings");
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async getCompanyData() {
      try {
        this.loading = true;

        const response = await this.$axios.get(
          `/customers/companies/${this.userData.advertiser.Companies[0].id}`
        );

        this.setCompanyData(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setCompanyData(data) {
      const parsedData = { ...data };

      this.companyRatingValue = parseFloat(parsedData.rating);
    },
    openReportAnswer(rating) {
      this.reportData.ratingId = rating.id;
      this.reportAnswerDialog = true;
    },
    async reportAnswer() {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Deseja realmente denunciar esta avaliação?",
            {
              color: "primary",
            }
          ))
        )
          return;

        this.loading = true;

        await this.$axios.put(
          `/customers/companies/ratings/${this.reportData.ratingId}/complaints`,
          {
            reasonOfTheReport: this.reportData.reason,
          }
        );

        this.reportAnswerDialog = false;

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    closeSuccessDialog() {
      this.reportData = {
        reason: null,
        ratingId: null,
      };

      this.ratings = [];
      this.loadRatings();

      this.successDialog = false;
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  mounted() {
    this.loadRatings();
    this.getCompanyData();
  },
};
</script>

<style>
.no-ratings {
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
  border: 1px solid rgb(224, 116, 116);
  background-color: rgb(245, 190, 190);
}

.additional-information {
  font-size: 0.8rem;
  color: dimgrey;
  padding-top: 0;
}

.rating-date {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.75rem;
  padding-top: 8px;
  padding-left: 15px;
}

.company-answer p {
  background-color: rgb(230, 230, 230);
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
}

.company-answer {
  padding-left: 30px;
}

.product-delivery p {
  margin-bottom: 0.4rem;
  font-size: 0.85rem;
  padding-left: 20px;
  color: aliceblue;
  background-color: rgb(31, 31, 161);
  border-radius: 10px;
}

.service-provison p {
  margin-bottom: 0.4rem;
  font-size: 0.85rem;
  padding-left: 20px;
  color: aliceblue;
  background-color: rgb(238, 135, 39);
  border-radius: 10px;
}
</style>
