<template>
  <div class="opening-hours">
    <v-card :loading="loading" flat>
      <v-card-text>
        <v-row class="mt-2 d-flex align-center flex-wrap" v-bind:key="thisDay.id" v-for="thisDay in days">
          <v-col md="4" cols="12">
            <v-switch :label="thisDay.day" v-model="thisDay.open" name="open-in-this-day"> </v-switch>
          </v-col>
          <v-col md="8" cols="12">
            <v-row
              class="mb-2 d-flex align-center flex-wrap"
              v-bind:key="thisTimeInterval.id"
              v-for="thisTimeInterval in thisDay.timeIntervals"
            >
              <v-col v-if="thisDay.open" sm="5">
                <v-text-field type="time" v-model="thisTimeInterval.startTime"></v-text-field>
              </v-col>
              <v-col v-if="thisDay.open" sm="5">
                <v-text-field type="time" v-model="thisTimeInterval.endTime"></v-text-field>
              </v-col>
              <v-col v-if="thisDay.open && thisDay.timeIntervals.length > 1" sm="1">
                <v-btn @click="removeInterval(thisDay.value, thisTimeInterval.id)" icon title="Remover este horário">
                  <v-icon> mdi-trash-can-outline </v-icon>
                </v-btn>
              </v-col>
              <v-col v-if="thisDay.open" sm="1">
                <v-btn icon @click="newInterval(thisDay.value)">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-divider class="mx-5"></v-divider>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="completeRegistration" class="primary darken-1" @click="saveOpeningHours()" :loading="loading">
          <v-icon right>mdi-arrow-down-bold</v-icon> Salvar e avançar</v-btn
        >

        <v-btn v-if="!completeRegistration" class="primary darken-1" @click="saveOpeningHours()" :loading="loading">
          <v-icon left>mdi-content-save</v-icon> Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  data() {
    return {
      loading: false,
      OperationHoursData: [],
      days: [],
    };
  },
  props: {
    adOperatingHours: {
      type: Array,
    },
    completeRegistration: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    announcementId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async loadOperationHours() {
      this.OperationHoursData = [...this.adOperatingHours];

      this.loadOpenDays();
    },
    loadOpenDays() {
      this.loading = true;

      for (let i = 0; i < this.OperationHoursData.length; i++) {
        let weekDay = this.OperationHoursData[i].weekDay; //pega o dia da semana
        //verifica o indice em days para o dia da semana correspondente
        let indexOfDay = this.days.findIndex((d) => d.value === weekDay);
        let lastPos = this.days[indexOfDay].timeIntervals.length - 1; //pega a ultima posição do array de intervalos

        if (
          this.days[indexOfDay].timeIntervals[lastPos].startTime === "00:00" &&
          this.days[indexOfDay].timeIntervals[lastPos].endTime === "00:00"
        ) {
          //se não tiver ocorrido edição, somente atualiza os valores do intervalo
          this.days[indexOfDay].timeIntervals[0].startTime = this.OperationHoursData[i].startTime;
          this.days[indexOfDay].timeIntervals[0].endTime = this.OperationHoursData[i].endTime;
          this.days[indexOfDay].timeIntervals[0].id = this.OperationHoursData[i].id;
          this.days[indexOfDay].timeIntervals[0].isLocally = null;
        } else {
          //acrescenta um novo intervalo
          this.days[indexOfDay].timeIntervals.push({
            id: this.OperationHoursData[i].id,
            startTime: this.OperationHoursData[i].startTime,
            endTime: this.OperationHoursData[i].endTime,
            isLocally: null,
          });
        }

        this.days[indexOfDay].open = true;
      }

      this.loading = false;
    },
    newInterval(value) {
      let dayIndex = this.days.findIndex((d) => d.value === value);
      let lastPos = this.days[dayIndex].timeIntervals.length - 1;
      let nextId = this.days[dayIndex].timeIntervals[lastPos].id + 1;
      this.days[dayIndex].timeIntervals.push({
        id: nextId,
        isLocally: true,
        startTime: "00:00",
        endTime: "00:00",
      });
    },
    removeInterval(dayValue, intervalId) {
      let dayIndex = this.days.findIndex((d) => d.value === dayValue);
      let intervalIndex = this.days[dayIndex].timeIntervals.findIndex((i) => i.id === intervalId);

      if (this.days[dayIndex].timeIntervals[intervalIndex].isLocally)
        //se ainda nao foi salvo na api
        this.dropIntervalLocally(dayIndex, intervalIndex);
      //deleta localmente
      else this.dropIntervalInApi(dayIndex, intervalId, intervalIndex); //deleta na api
    },
    async dropIntervalInApi(dayIndex, intervalId, intervalIndex) {
      try {
        this.loading = true;

        await this.$axios.delete(`/customers/posts/${this.announcementId}/openinghours/${intervalId}`);

        this.dropIntervalLocally(dayIndex, intervalIndex);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    dropIntervalLocally(dayIndex, intervalIndex) {
      this.days[dayIndex].timeIntervals.splice(intervalIndex, 1);
      this.loading = false;
    },
    async confirmModal(title, msg, options) {
      return await this.$root.$confirm(title, msg, options);
    },
    async saveOpeningHours() {
      try {
        if (
          !(await this.$root.$confirm("Confirmação", "Salvar dados?", {
            color: "primary",
          }))
        )
          return;

        let daysAux = JSON.parse(JSON.stringify(this.days));
        let openDays = [];

        for (const day of daysAux) {
          if (day.open) {
            for (const interval of day.timeIntervals) {
              openDays.push({
                id: interval.id,
                weekDay: day.value,
                isLocally: interval.isLocally ? true : null,
                startTime: interval.startTime,
                endTime: interval.endTime,
              });
            }
          }
        }

        if (openDays.length === 0) {
          this.$root.$errorDialog(["Informe ao menos um horário de funcionamento"]);

          return;
        }

        this.loading = true;

        //faz requisição
        await this.$axios.put(`/customers/posts/${this.announcementId}/openinghours`, openDays);

        this.$emit("saved", false);

        this.$toast.success("Dados salvos com sucesso");

        this.initDays();
        this.loadOperationHours();
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    initDays() {
      this.days = [
        {
          day: "Domingo",
          value: "sunday",
          timeIntervals: [
            {
              id: 1,
              isLocally: true,
              startTime: "00:00",
              endTime: "00:00",
            },
          ],
          open: false,
        },
        {
          day: "Segunda-feira",
          value: "monday",
          timeIntervals: [
            {
              id: 1,
              isLocally: true,
              startTime: "00:00",
              endTime: "00:00",
            },
          ],
          open: false,
        },
        {
          day: "Terça-feira",
          value: "tuesday",
          timeIntervals: [
            {
              id: 1,
              isLocally: true,
              startTime: "00:00",
              endTime: "00:00",
            },
          ],
          open: false,
        },
        {
          day: "Quarta-feira",
          value: "wednesday",
          timeIntervals: [
            {
              id: 1,
              isLocally: true,
              startTime: "00:00",
              endTime: "00:00",
            },
          ],
          open: false,
        },
        {
          day: "Quinta-feira",
          value: "thursday",
          timeIntervals: [
            {
              id: 1,
              isLocally: true,
              startTime: "00:00",
              endTime: "00:00",
            },
          ],
          open: false,
        },
        {
          day: "Sexta-feira",
          value: "friday",
          timeIntervals: [
            {
              id: 1,
              isLocally: true,
              startTime: "00:00",
              endTime: "00:00",
            },
          ],
          open: false,
        },
        {
          day: "Sábado",
          value: "saturday",
          timeIntervals: [
            {
              id: 1,
              isLocally: true,
              startTime: "00:00",
              endTime: "00:00",
            },
          ],
          open: false,
        },
      ];
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  created() {
    this.initDays();
    this.loadOperationHours();
  },
};
</script>

<style></style>
