<template>
  <div>
    <v-card>
      <v-card-title>Minhas empresas</v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="id"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                clearable
                @click:append="loadCompaniesData()"
                @click:clear="clearFilters()"
                placeholder="Pesquisar"
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn small rounded color="success" @click="openInsertScreen">
                Cadastrar <v-icon right dark> mdi-plus </v-icon></v-btn
              >
            </v-toolbar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              icon
              title="Editar configurações desta empresa"
              @click="openSettingsScreen(item.id)"
            >
              <v-icon> mdi-cogs </v-icon>
            </v-btn>

            <v-btn
              icon
              title="Excluir esta empresa"
              @click="deleteCompany(item.id)"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resultsPerPage: 15,
      totalRows: 0,
      page: 1,
      search: null,
      loading: false,
      headers: [
        { text: "Nome", value: "name", sortable: true },
        {
          text: "Tipo",
          value: "typeName",
          sortable: false,
        },
        {
          text: "CPF/CNPJ",
          value: "taxDocument",
          sortable: false,
        },
        {
          text: "Avaliação",
          value: "rating",
          sortable: false,
        },
        { text: "Ações", value: "actions", sortable: false },
      ],
      items: [],
    };
  },
  methods: {
    setCompaniesData(data) {
      const copyOfData = [...data];

      this.items = copyOfData.map((item) => {
        item.name =
          item.type === "legal-person" ? item.fantasyName : item.companyName;

        item.typeName =
          item.type === "legal-person" ? "Pessoa jurídica" : "Pessoa física";

        if (!item.rating) item.rating = "-";

        return item;
      });
    },
    async loadCompaniesData() {
      try {
        this.loading = true;

        let url = `/customers/companies`;
        if (this.search) url += `?search=${this.search}`;

        const response = await this.$axios.get(url);

        if (response.data.length) this.setCompaniesData(response.data);

        this.loading = false;
      } catch (error) {
        this.loading = false;
       console.error(error);
      }
    },
    async confirmDialog(message) {
      return await this.$root.$confirm("Atenção", message, {
        color: "primary",
      });
    },
    deleteCompanyInArray(cashierId) {
      const index = this.items.findIndex((item) => item.id === cashierId);

      if (index !== -1) this.items.splice(index, 1);
    },
    async deleteCompany() {
      //   try {
      //     if (
      //       !(await this.confirmDialog(
      //         "Deseja realmente deletar o caixa selecionado ?"
      //       ))
      //     )
      //       return;
      //     this.loading = true;
      //     const response = await axios.delete(
      //       `${baseApiUrl}/cashier/${cashierId}`
      //     );
      //     this.deleteCompanyInArray(cashierId);
      //     this.loading = false;
      //   } catch (error) {
      //     this.loading = false;
      //     const errorHandled = errorHandler.treatError(error);
      //     this.$root.$errorDialog(errorHandled, {
      //       width: "800",
      //       color: "primary",
      //     });
      //   }
    },
    clearFilters() {
      this.search = null;
      this.loadCompaniesData();
    },
    openInsertScreen() {
      this.$router.push("/empresas/registrar");
    },
    openSettingsScreen(companyId) {
      this.$router.push(`/empresa/${companyId}/configurar`);
    },
  },
  computed: {},
  mounted() {
    this.loadCompaniesData();
  },
};
</script>

<style>
</style>