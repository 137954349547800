<template>
  <v-card color="grey lighten-4">
    <v-card-title> Páginal inicial </v-card-title>
    <v-card-text>
      <h3 class="mb-3">
        Seja bem-vindo(a) à central do anunciante!
      </h3>

      <!-- <p>Utilize o menu ao seu lado esquerdo para navegar no sistema.</p> -->
      <p>No menu ao lado você poderá administrar seus anúncios, seu financeiro e dados cadastrais.</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>
