<template>
  <v-card :disabled="loading">
    <v-expansion-panels v-model="panel" class="pa-4">
      <v-expansion-panel>
        <v-expansion-panel-header> Dados básicos </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <v-select
                class="required"
                v-model="form.type"
                :items="[
                  { value: 'physical-person', text: 'Pessoa física' },
                  { value: 'legal-person', text: 'Pessoa jurídica' },
                ]"
                label="Tipo de empresa"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                class="required"
                v-model="form.companyName"
                :label="companyNameLabel"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="form.type === 'legal-person'">
            <v-col>
              <v-text-field
                class="required"
                v-model="form.fantasyName"
                label="Nome fantasia"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                class="required"
                label="CPF/CNPJ: "
                v-model="form.taxDocument"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Endereço </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="10">
              <v-text-field
                class="required"
                label="Logradouro"
                v-model="form.address"
                id="address"
                maxlength="254"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Número"
                v-model="form.number"
                id="address-number"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8">
              <v-text-field
                class="required"
                label="Bairro"
                v-model="form.district"
                id="district"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Complemento"
                v-model="form.complement"
                id="complement"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field
                class="required"
                label="Cep:"
                v-model="form.zipCode"
                placeholder="xxxxx-xxx"
                v-mask="'#####-###'"
              ></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-select
                class="required mb-2 mr-sm-2 mb-sm-0"
                :loading="loadingCities"
                v-model="form.CityId"
                :items="cities"
                label="Cidade:"
                id="company-city"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                class="required"
                label="Coordenadas: "
                v-model="form.coordinate"
                append-icon="mdi-map-marker"
                @click:append="getPosition"
                placeholder="-xx.xxxxxx, -xx.xxxxxx"
                hint="Clique no ícone ao lado para pegar automaticamente"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <span class="grey--text"
                >Coordenadas fornecidas pelo computador podem ser imprecisas.
                recomendamos que pegue automaticamente por um dispositivo móvel
                ou pelo maps.
              </span>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          Informações de contato
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <h3>Telefones</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn text small @click="addPhone()">
                    <i class="fa fa-plus"></i> Adicionar telefone +
                  </v-btn>
                </v-col>
              </v-row>

              <v-row
                class="mt-2 d-flex align-center"
                v-bind:key="index"
                v-for="(thisPhone, index) in phones"
              >
                <v-col cols="10">
                  <v-text-field
                    v-model="thisPhone.number"
                    v-mask="'(##) #####-####'"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    icon
                    small
                    title="Remover este telefone"
                    @click="removePhone(thisPhone.id, index)"
                  >
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-text-field v-model="form.email" label="E-mail"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.website"
                label="Site da empresa"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="primary darken-1" :loading="loading" @click="save()">
        <v-icon left>mdi-content-save</v-icon> Salvar</v-btn
      >
    </v-card-actions>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Dados atualizados com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeSucessDialog()"
            >Fechar</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mask } from "vue-the-mask";
import errorHandler from "@/helpers/error_handler";
import { mapState } from "vuex";

export default {
  components: {},
  directives: { mask },
  data() {
    return {
      panel: 0,
      loading: false,
      successDialog: false,
      loadingCities: false,
      cities: [],
      phones: [],
      form: {
        coordinate: null,
        type: "legal-person",
      },
    };
  },
  props: {
    companyData: {
      type: Object,
    },
    companyFacilities: {
      type: Array,
    },
    companyPhones: {
      type: Array,
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
    companyNameLabel() {
      return this.form.type === "legal-person"
        ? "Razão social"
        : "Nome da empresa";
    },
    companyId() {
      return this.$route.params.companyId;
    },
  },
  methods: {
    async save() {
      if (
        !(await this.$root.$confirm("Confirmação", "Salvar dados?", {
          color: "primary",
        }))
      )
        return;

      const errors = this.validateForm();

      if (errors.length) return this.$root.$errorDialog(errors);

      this.update();
    },
    async update() {
      try {
        this.loading = true;

        let formattedData = await this.formatDataToUpdate(this.form);

        await this.$axios.put(
          `/customers/companies/${this.userData.advertiser.Companies[0].id}`,
          formattedData
        );

        this.successDialog = true;
      } catch (error) {
        console.error(error);
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    formatDataToUpdate() {
      let auxForm = this.form;

      auxForm.phones = this.phones.filter((p) => !p.id);

      return auxForm;
    },
    formatDataToEdit() {
      let auxForm = this.form;

      auxForm.phones = this.phones.map((phone) => {
        return phone.number;
      });

      auxForm.facilities = this.selectedFacilities;

      return auxForm;
    },
    validateForm() {
      let errors = [];

      if (!this.form.type) errors.push("Tipo de empresa obrigatório");

      if (!this.form.companyName) errors.push("Nome da empresa obrigatório");

      if (!this.form.taxDocument) errors.push("Nome da empresa obrigatório");

      if (!this.form.address) errors.push("Logradouro obrigatório");

      if (!this.form.district) errors.push("Bairro obrigatório");

      if (!this.form.zipCode) errors.push("CEP obrigatório");

      if (!this.phones || !this.phones.length)
        errors.push("Informe ao menos um telefone");

      if (!this.form.coordinate) errors.push("Coordenada obrigatório");

      if (!this.form.CityId) errors.push("Cidade obrigatória");

      return errors;
    },
    addPhone() {
      this.phones.push({
        number: null,
      });
    },
    removePhone(id, index) {
      if (id) {
        this.removePhoneFromApi(id);
      } else {
        this.removePhoneFromArrayByIndex(index);
      }
    },
    removePhoneFromArrayByIndex(index) {
      this.phones.splice(index, 1);
    },
    removePhoneFromArrayById(id) {
      //procura o indice passado como parametro no array de respostas
      let indexFound = this.phones.findIndex((p) => p.id === id);

      //realiza a remoção
      if (indexFound !== -1) this.phones.splice(indexFound, 1);
    },
    async removePhoneFromApi(id) {
      try {
        this.loading = true;

        await this.$axios.delete(
          `/customers/companies/${this.userData.advertiser.Companies[0].id}/phones/${id}`
        );

        this.removePhoneFromArrayById(id);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    getPosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.form.coordinate = `${position.coords.latitude}, ${position.coords.longitude}`;
          },
          () => {
            this.handleErrors("Erro ao pegar localização");
          }
        );
      } else {
        this.handleErrors("Geolocalização não suportada pelo navegador");
        return;
      }
    },
    async getCompanyData() {
      try {
        this.loading = true;

        const response = await this.$axios.get(
          `/customers/companies/${this.userData.advertiser.Companies[0].id}`
        );

        this.setCompanyData(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setCompanyData(data) {
      if (data.coordinate)
        data.coordinate = `${data.coordinate.coordinates[1]},${data.coordinate.coordinates[0]}`;
      const { CompanyPhones: companyPhones, ...parsedData } = { ...data };

      this.form = parsedData;
      this.phones = companyPhones.map((p) => ({ id: p.id, number: p.number }));
    },
    async getCities() {
      try {
        this.loadingCities = true;

        const res = await this.$axios.get("/cities");

        this.setCitiesData(res.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loadingCities = false;
      }
    },
    setCitiesData(cities) {
      this.cities = cities.map((c) => {
        return {
          value: c.id,
          text: `${c.name} - ${c.uf}`,
        };
      });
    },
    async closeSucessDialog() {
      this.successDialog = false;

      await this.getCompanyData();
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  mounted() {
    this.getCities();
    this.getCompanyData();
  },
};
</script>

<style></style>
