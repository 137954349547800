<template>
  <div>
    <v-card>
      <v-card-title>Estatísticas</v-card-title>

      <div class="pa-4">
        <v-select
          :items="adverts"
          placeholder="Selecione um anúncio"
          label="Anúncio"
          v-model="selectedAd"
          :loading="loadingAds"
          solo
        ></v-select>
      </div>

      <div class="pa-4 results-period">
        <v-select
          :items="periodOptions"
          placeholder="Selecione um período"
          label="Período"
          v-model="selectedPeriod"
          solo
        ></v-select>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="viewStatistics">
          <v-icon left>mdi-eye</v-icon> Visualizar</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-card class="mt-4">
      <div class="summary-cards pa-4">
        <v-row>
          <v-col cols="4">
            <v-card elevation="2" class="summary-card" color="#1C77C4" dark>
              <v-container>
                <v-row>
                  <v-col class="summary-card-number">{{
                    clicks ? clicks.length : null
                  }}</v-col>
                </v-row>
                <v-row>
                  <v-col class="summary-card-title">Cliques</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card elevation="2" class="summary-card" color="#FA9500" dark>
              <v-container>
                <v-row>
                  <v-col class="summary-card-number">{{
                    views ? views.length : null
                  }}</v-col>
                </v-row>
                <v-row>
                  <v-col class="summary-card-title">Visualizações</v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card elevation="2" class="summary-card" color="#6E2594" dark>
              <v-container>
                <v-row>
                  <v-col class="summary-card-number">{{
                    contactRequests ? contactRequests.length : null
                  }}</v-col>
                </v-row>
                <v-row>
                  <v-col class="summary-card-title"
                    >Solicitações de contato</v-col
                  >
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div class="views-chart pa-4">
        <div class="text-h4">Visualizações</div>
        <BarChart :chartData="chartDataForViews" :options="chartOptions" />
      </div>

      <div class="click-chart pa-4">
        <div class="text-h4">Cliques</div>
        <BarChart :chartData="chartDataForClicks" :options="chartOptions" />
      </div>

      <div class="contact-requests-chart pa-4">
        <div class="text-h4">Solicitações de contato</div>
        <BarChart :chartData="chartDataForContacts" :options="chartOptions" />
      </div>
    </v-card>
  </div>
</template>

<script>
import BarChart from "@/components/Statistics/BarChart";
import errorHandler from "@/helpers/error_handler";

export default {
  components: { BarChart },
  data: () => ({
    chartDataForClicks: null,
    chartDataForContacts: null,
    chartDataForViews: null,
    views: null,
    clicks: null,
    contactRequests: null,
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 1,
            },
          },
        ],
      },
    },
    periodOptions: [
      {
        text: "Hoje",
        value: "today",
      },
      {
        text: "Últimos 7 dias",
        value: "last-seven-days",
      },
      {
        text: "Últimos 30 dias",
        value: "last-thirty-days",
      },
      {
        text: "Últimos 6 meses",
        value: "last-six-months",
      },
      {
        text: "Últimos 12 meses",
        value: "last-twelve-months",
      },
    ],
    adverts: [],
    selectedAd: null,
    selectedPeriod: null,
    loading: false,
    loadingAds: false,
  }),
  methods: {
    viewStatistics() {
      const errors = this.validate();

      if (errors.length) return this.$root.$errorDialog(errors);

      this.loadStats();
    },
    validate() {
      const errors = [];

      if (!this.selectedAd)
        errors.push("Selecione um anúncio para visualizar estatísticas");

      if (!this.selectedPeriod)
        errors.push("Selecione um período para visualizar estatísticas");

      return errors;
    },
    async loadStats() {
      try {
        this.loading = true;

        let url = `/customers/posts/${
          this.selectedAd
        }/statistics?${this.formatPeriod()}`;

        const response = await this.$axios.get(url);

        this.setStats(response.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    setStats(data) {
      const parsedData = [...data];

      this.clicks = parsedData.filter((s) => s.type === "click");

      this.views = parsedData.filter((s) => s.type === "visualization");

      this.contactRequests = parsedData.filter(
        (s) => s.type === "contact-request"
      );

      const labels = this.generateChartLabels();

      this.chartDataForClicks = {
        labels,
        datasets: [this.generateChartData(this.clicks, labels)],
      };

      this.chartDataForContacts = {
        labels,
        datasets: [this.generateChartData(this.contactRequests, labels)],
      };

      this.chartDataForViews = {
        labels,
        datasets: [this.generateChartData(this.views, labels)],
      };
    },
    async loadAds() {
      try {
        this.loadingAds = true;

        let url = `/customers/posts`;

        const response = await this.$axios.get(url);

        this.setAds(response.data || []);
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingAds = false;
      }
    },
    setAds(data) {
      const parsedData = [...data];

      const mappedData = parsedData.map((a) => ({
        text: `#${a.id} - ${a.AdCategory ? a.AdCategory.name : "-"}`,
        value: a.id,
      }));

      this.adverts = mappedData;
    },
    formatPeriod() {
      if (!this.periodRange) return null;

      return `startPeriod=${this.periodRange.start.format(
        "YYYY-MM-DD"
      )}&endPeriod=${this.periodRange.end.format("YYYY-MM-DD")}`;
    },
    generateChartLabels() {
      const labels = [];

      const by =
        this.selectedPeriod === "today" ||
        this.selectedPeriod === "last-seven-days" ||
        this.selectedPeriod === "last-thirty-days"
          ? "days"
          : "months";

      const format = by === "days" ? "DD/MM/YYYY" : "MMM/YYYY";

      for (let i of this.periodRange.by(by)) labels.push(i.format(format));

      return labels;
    },
    generateChartData(data, labels) {
      const dataSet = { data: [], backgroundColor: [] };

      const by =
        this.selectedPeriod === "today" ||
        this.selectedPeriod === "last-seven-days" ||
        this.selectedPeriod === "last-thirty-days"
          ? "days"
          : "months";

      const format = by === "days" ? "DD/MM/YYYY" : "MMM/YYYY";

      for (const label of labels) {
        let qtdFound = data.filter(
          (d) => this.$moment(d.createdAt).format(format) === label
        ).length;

        let backgroundColor = this.getRandomColor();

        dataSet.data.push(qtdFound);
        dataSet.backgroundColor.push(backgroundColor);
      }

      return dataSet;
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
  },
  computed: {
    periodRange() {
      switch (this.selectedPeriod) {
        case "today":
          return this.$moment.range(this.$moment(), this.$moment());
        case "last-seven-days":
          return this.$moment.range(
            this.$moment().subtract(7, "days"),
            this.$moment()
          );
        case "last-thirty-days":
          return this.$moment.range(
            this.$moment().subtract(30, "days"),
            this.$moment()
          );
        case "last-six-months":
          return this.$moment.range(
            this.$moment().subtract(6, "months"),
            this.$moment()
          );
        case "last-twelve-months":
          return this.$moment.range(
            this.$moment().subtract(12, "months"),
            this.$moment()
          );

        default:
          return null;
      }
    },
  },
  mounted() {
    this.loadAds();
  },
};
</script>

<style scoped>
.summary-card-title,
.summary-card-number {
  text-align: center;
}

.summary-card-number {
  font-size: 40px;
}

.summary-card-number {
  font-weight: bold;
}
</style>
