<template>
  <v-card>
    <v-toolbar flat>
      <v-app-bar-nav-icon
        ><v-btn title="voltar" icon @click="$router.go(-1)"
          ><v-icon>mdi-keyboard-backspace</v-icon></v-btn
        ></v-app-bar-nav-icon
      >
      <v-card-title>Atualizar configurações do anúncio</v-card-title>
    </v-toolbar>

    <v-tabs v-model="tabIndex">
      <v-tab> Informações gerais </v-tab>
      <v-tab v-if="coverIsAllowed && logoIsAllowed"> Capa e logo </v-tab>
      <v-tab v-if="imagesIsAllowed"> Fotos </v-tab>
      <v-tab v-if="videosIsAllowed"> Videos </v-tab>
      <v-tab v-if="filesIsAllowed"> Arquivos para download </v-tab>
      <v-tab v-if="openingHoursIsAllowed"> Horários de funcionamento </v-tab>
      <v-tab v-if="paymentMethodsIsAllowed"> Formas de pagamento </v-tab>
    </v-tabs>

    <div class="text-center pt-5 pb-5" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <v-tabs-items v-model="tabIndex" v-else>
      <v-tab-item
        ><AnnouncementForm
          :mod="'update'"
          :adData="adData"
          :adPhones="adPhones"
          :adFacilities="adFacilities"
          :adPlanFeatures="adPlanFeatures"
          :dialog="true"
      /></v-tab-item>
      <v-tab-item v-if="coverIsAllowed && logoIsAllowed">
        <CoverOrLogo />
      </v-tab-item>
      <v-tab-item v-if="imagesIsAllowed">
        <AdImages />
      </v-tab-item>
      <v-tab-item v-if="videosIsAllowed">
        <AdVideos />
      </v-tab-item>
      <v-tab-item v-if="filesIsAllowed">
        <AdFiles />
      </v-tab-item>
      <v-tab-item v-if="openingHoursIsAllowed"
        ><OpeningHours :adOperatingHours="adOperatingHours"
      /></v-tab-item>
      <v-tab-item v-if="paymentMethodsIsAllowed"
        ><PaymentMethods :adPaymentMethods="adPaymentMethods"
      /></v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import AnnouncementForm from "@/components/Announcement/AnnouncementForm";
import AdImages from "@/components/Announcement/AdImages";
import AdVideos from "@/components/Announcement/AdVideos";
import AdFiles from "@/components/Announcement/AdFiles";
import OpeningHours from "@/components/Announcement/OpeningHours";
import PaymentMethods from "@/components/Announcement/PaymentMethods";
import CoverOrLogo from "@/components/Announcement/CoverOrLogo";
import errorHandler from "@/helpers/error_handler";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    AnnouncementForm,
    AdImages,
    AdVideos,
    AdFiles,
    OpeningHours,
    PaymentMethods,
    CoverOrLogo,
  },
  computed: {
    ...mapGetters("adverts", ["validateSpecificFeature"]),
    adId() {
      return this.$route.params.id;
    },
    coverIsAllowed() {
      return this.validateSpecificFeature(7);
    },
    logoIsAllowed() {
      return this.validateSpecificFeature(10);
    },
    imagesIsAllowed() {
      return this.validateSpecificFeature(16);
    },
    videosIsAllowed() {
      return this.validateSpecificFeature(19);
    },
    filesIsAllowed() {
      return this.validateSpecificFeature(18);
    },
    openingHoursIsAllowed() {
      return this.validateSpecificFeature(14);
    },
    paymentMethodsIsAllowed() {
      return this.validateSpecificFeature(15);
    },
  },
  data() {
    return {
      tabIndex: null,
      loading: false,
      adData: {},
      adPhones: [],
      adFacilities: [],
      adOperatingHours: [],
      adPaymentMethods: [],
      adPlanFeatures: [],
    };
  },
  methods: {
    ...mapActions("adverts", ["setPlanFeatures"]),
    async loadAdData() {
      try {
        this.loading = true;

        const res = await this.$axios.get(`/customers/posts/${this.adId}`);

        this.formatAdData(res.data);
      } catch (error) {
        this.loading = false;
        this.handleErrors(error);
      }
    },
    formatAdData(data) {
      let copyOfData = { ...data };

      this.adPhones = copyOfData.AdPhones;
      delete copyOfData.CompanyPhones;

      this.adFacilities = copyOfData.Facilities.map((f) => {
        return f.id;
      });
      delete copyOfData.Facilities;

      this.adOperatingHours = copyOfData.OperatingHours;
      delete copyOfData.OperatingHours;

      this.adPaymentMethods = copyOfData.PaymentMethods;
      delete copyOfData.PaymentMethods;

      this.adPlanFeatures = copyOfData.Plan.Features;

      this.setPlanFeatures(copyOfData.Plan.Features);

      this.adData = copyOfData;

      this.loading = false;
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  mounted() {
    this.loadAdData();
  },
};
</script>

<style></style>
