<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header> Filtros </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row>
              <v-col>
                <v-select
                  :items="cities"
                  v-model="filters.city"
                  label="Cidade"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  :items="statuses"
                  v-model="filters.status"
                  label="Status"
                ></v-select>
              </v-col>
            </v-row>

            <div class="text-right">
              <v-btn @click="clearFilters()" text class="mr-2"
                >Limpar filtros</v-btn
              >
              <v-btn color="primary" @click="loadCharges()">Filtrar</v-btn>
            </div>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card class="mt-4">
      <v-card-title>Financeiro</v-card-title>

      <v-data-table
        :headers="headers"
        :items="charges"
        disable-pagination
        hide-default-footer
        :loading="loading"
      >
        <template v-slot:[`item.Announcements`]="{ item }">
          {{ item.Announcements.map((ad) => ad.id).join(', ') }}
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          {{ $moment(item.createdAt).format("DD/MM/YYYY") }}
        </template>

        <template v-slot:[`item.dueDate`]="{ item }">
          {{ $moment(item.dueDate).format("DD/MM/YYYY") }}
        </template>

        <template v-slot:[`item.payDay`]="{ item }">
          {{ item.payDay ? $moment(item.payDay).format("DD/MM/YYYY") : "-" }}
        </template>

        <template v-slot:[`item.value`]="{ item }">
          {{ formatCurrencyValue(item.value) }}
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="statusColor(item.status)">
            {{ statusName(item.status) }}
          </v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            class="mr-2"
            title="Pagar / Visualizar cobrança"
            @click="pay(item.id)"
          >
            {{ actionButtonIcon(item.status) }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-pagination
      v-model="filters.currentPage"
      :length="numberOfPages"
      :total-visible="resultsPerPage"
      @change="loadCharges"
      class="d-flex justify-content-end mt-2"
    >
      <template v-slot:page></template>
    </v-pagination>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
export default {
  data: () => ({
    headers: [
      { text: "#ID", value: "id" },
      { text: "Anúncios (ID's)", value: "Announcements" },
      { text: "Data de emissão", value: "createdAt" },
      { text: "Data de vencimento", value: "dueDate" },
      { text: "Data de pagamento", value: "payDay" },
      { text: "Valor (R$)", value: "value", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Ações", value: "actions", sortable: false },
    ],
    statuses: [
      { text: "Pendente", value: "pending" },
      { text: "Paga", value: "paid" },
      { text: "Pagamento em processamento", value: "processing-payment" },
      { text: "Erro no pagamento", value: "payment-error" },
      { text: "Cancelada", value: "canceled" },
    ],
    loading: false,
    loadingCities: false,
    cities: [],
    charges: [],
    resultsPerPage: 15,
    totalRows: 0,
    filters: {
      currentPage: 1,
    },
  }),
  methods: {
    async loadCharges() {
      try {
        this.loading = true;

        let url = `/customers/charges`;

        const response = await this.$axios.get(url, { params: this.filters });

        this.setCharges(response.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    setCharges(data) {
      const parsedData = { ...data };

      this.totalRows = parsedData.count;
      this.charges = parsedData.rows;
    },
    async loadCities() {
      try {
        this.loadingCities = true;

        let url = `/cities`;

        const response = await this.$axios.get(url);

        this.setCities(response.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingCities = false;
      }
    },
    setCities(data) {
      const parsedData = [...data];

      this.cities = parsedData.map((c) => ({
        text: `${c.name} - ${c.uf}`,
        value: c.id,
      }));
    },
    clearFilters() {
      this.filters = { currentPage: 1 };

      this.loadCharges();
    },
    pay(id) {
      this.$router.push(`/cobrancas/${id}/pagar`);
    },
    statusColor(status) {
      switch (status) {
        case "pending":
          return "warning";

        case "paid":
          return "success";

        case "processing-payment":
          return "info";

        case "payment-error":
          return "error";

        case "canceled":
          return "gray";

        default:
          return "white";
      }
    },
    statusName(status) {
      switch (status) {
        case "pending":
          return "Pendente";

        case "paid":
          return "Pago";

        case "canceled":
          return "Cancelado";

        case "processing-payment":
          return "Pagamento em processamento";

        case "payment-error":
          return "Erro no pagamento";

        default:
          return "Status desconhecido";
      }
    },
    formatCurrencyValue(value) {
      return parseFloat(value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    actionButtonIcon(status) {
      return status === "payment-error" || status === "pending"
        ? "mdi-barcode"
        : "mdi-eye";
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  mounted() {
    this.loadCharges();
    this.loadCities();
  },
};
</script>

<style>
</style>