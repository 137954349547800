<template>
  <div class="ad-files">
    <div class="text-center pt-5 pb-5" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-card v-else flat>
      <v-card-text>
        <v-row class="mt-2" v-if="adFiles.length">
          <v-col class="py-2 d-flex flex-wrap">
            <div class="ma-3" v-for="(item, i) in adFiles" :key="i">
              <v-row no-gutters>
                <v-col class="d-flex justify-center">
                  <v-img max-width="85" :src="pdfIcon"></v-img>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="d-flex flex-column justify-center">
                  <span class="file-label">{{ item.file }}</span>
                  <v-btn @click="showFile(item)" class="mt-2" x-small rounded>
                    <v-icon left v-text="'mdi-eye'"></v-icon>
                    Ver
                  </v-btn>
                  <v-btn class="mt-2" x-small @click="deleteFile(item.id)" rounded>
                    <v-icon left v-text="'mdi-close-circle-outline'"></v-icon>
                    Remover
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col>
            <v-alert border="top" colored-border type="info" elevation="2">
              Insira os arquivos que aparecerão no seu anúncio
            </v-alert>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-file-input v-model="files" ref="files" show-size multiple label="Adicionar arquivo (s):" accept=".pdf"></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary darken-1" @click="saveFiles()"> <v-icon left>mdi-content-save</v-icon> Salvar arquivo(s)</v-btn>

        <v-btn v-if="completeRegistration" class="primary darken-1" @click="nextStep()">
          <v-icon right>mdi-arrow-down-bold</v-icon> Avançar</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import { cloudUrl } from "@/config/config.json";
import pdfIcon from "@/assets/pdf-icon.png";

export default {
  props: {
    completeRegistration: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    announcementId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      loading: false,
      fileSrc: null,
      pdfIcon,
      files: [],
      adFiles: [],
    };
  },
  methods: {
    async saveFiles() {
      try {
        if (
          !(await this.$root.$confirm("Confirmação", "Salvar dados?", {
            color: "primary",
          }))
        )
          return;

        const errors = this.validateForm();

        if (errors.length) return this.$root.$errorDialog(errors);

        this.loading = true;

        let formData = new FormData();

        for (let i = 0; i < this.files.length; i++) {
          formData.append("file", this.files[i]);
        }

        await this.$axios.post(`/customers/posts/${this.announcementId}/files`, formData);

        this.getAdFiles();

        this.files = [];
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    validateForm() {
      let errors = [];

      if (!this.files.length) errors.push("Informe ao menos um arquivo!");

      if (this.files.length + this.adFiles.length > 10) errors.push("São permitidos no máximo 10 arquivos no total!");

      return errors;
    },
    showFile(fileData) {
      try {
        window.open(`${cloudUrl}/${fileData.hash}${fileData.file}`, "_blank");
      } catch (error) {
        this.handleErrors(error);
      }
    },
    async getAdFiles() {
      try {
        this.loading = true;

        const response = await this.$axios.get(`/customers/posts/${this.announcementId}/files`);

        this.setAdFilesData(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setAdFilesData(data) {
      const copyOfData = [...data];

      this.adFiles = copyOfData;
    },
    async deleteFile(fileId) {
      try {
        if (
          !(await this.$root.$confirm("Atenção", "Deseja realmente excluir o file do anúncio?", {
            color: "primary",
          }))
        )
          return;

        this.loading = true;

        await this.$axios.delete(`/customers/posts/${this.announcementId}/files/${fileId}`);

        this.deleteFileInArray(fileId);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorHandled = errorHandler.treatError(error);
        this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    deleteFileInArray(fileId) {
      const index = this.adFiles.findIndex((file) => file.id === fileId);

      if (index !== -1) this.adFiles.splice(index, 1);
    },
    nextStep() {
      this.$emit("saved", false);
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  created() {
    this.getAdFiles();
  },
};
</script>

<style scoped>
.file-label {
  white-space: nowrap;
  width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2px;
  text-align: center;
}
</style>
