<template>
  <div class="ad-videos">
    <div class="text-center pt-5 pb-5" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-card v-else flat>
      <v-card-text>
        <v-row class="mt-2" v-if="adVideos.length">
          <v-col>
            <v-list flat>
              <v-list-item-group color="primary">
                <v-list-item v-for="(item, i) in adVideos" :key="i" @click="selectVideo(i)">
                  <v-list-item-icon>
                    <v-icon v-text="'mdi-video'"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.video"></v-list-item-title>
                    <v-btn v-if="item.active" class="mt-2" x-small @click="deleteVideo(item.id)" rounded>
                      <v-icon left v-text="'mdi-close-circle-outline'"></v-icon>
                      Remover
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>

          <v-col class="d-flex align-center">
            <v-alert v-if="!videoSrc" border="top" colored-border type="info" elevation="2">
              Clique em um dos vídeos ao lado para ver uma prévia
            </v-alert>
            <video v-else :src="videoSrc" width="400" height="200" controls></video>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col>
            <v-alert border="top" colored-border type="info" elevation="2">
              Insira os vídeos que aparecerão no seu anúncio
            </v-alert>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-file-input
              v-model="files"
              ref="files"
              show-size
              multiple
              label="Adicionar arquivo (s):"
              accept="video/*"
              hint="Tamanho máximo permitido: 100MB"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary darken-1" @click="saveVideos()"> <v-icon left>mdi-content-save</v-icon> Salvar video(s)</v-btn>

        <v-btn v-if="completeRegistration" class="primary darken-1" @click="nextStep()">
          <v-icon right>mdi-arrow-down-bold</v-icon> Avançar</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import { cloudUrl } from "@/config/config.json";

export default {
  props: {
    completeRegistration: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    announcementId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      loading: false,
      videoSrc: null,
      files: [],
      adVideos: [],
    };
  },
  methods: {
    async saveVideos() {
      try {
        if (
          !(await this.$root.$confirm("Confirmação", "Salvar dados?", {
            color: "primary",
          }))
        )
          return;

        const errors = this.validateForm();

        if (errors.length) return this.$root.$errorDialog(errors);

        this.loading = true;

        let formData = new FormData();

        for (let i = 0; i < this.files.length; i++) {
          formData.append("file", this.files[i]);
        }

        await this.$axios.post(`/customers/posts/${this.announcementId}/videos`, formData);

        this.getAdVideos();

        this.files = [];
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    validateForm() {
      let errors = [];

      if (!this.files.length) errors.push("Informe ao menos um arquivo!");

      if (this.files.filter((f) => f.size > 100000000).length) errors.push("O vídeo deve ter no máximo 100MB!");

      return errors;
    },
    selectVideo(index) {
      const previousActiveIndex = this.adVideos.findIndex((v) => v.active === true);

      if (previousActiveIndex != -1) this.adVideos[previousActiveIndex].active = false;

      this.adVideos[index].active = true;
      const videoData = this.adVideos[index];

      this.videoSrc = `${cloudUrl}/${videoData.hash}${videoData.video}`;
    },
    async getAdVideos() {
      try {
        this.loading = true;

        const response = await this.$axios.get(`/customers/posts/${this.announcementId}/videos`);

        this.setAdVideosData(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setAdVideosData(data) {
      const copyOfData = [...data];

      this.adVideos = copyOfData.map((v) => {
        return {
          active: false,
          ...v,
        };
      });
    },
    async deleteVideo(videoId) {
      try {
        if (
          !(await this.$root.$confirm("Atenção", "Deseja realmente excluir o video do anúncio?", {
            color: "primary",
          }))
        )
          return;

        this.loading = true;

        await this.$axios.delete(`/customers/posts/${this.announcementId}/videos/${videoId}`);

        this.deleteVideoInArray(videoId);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorHandled = errorHandler.treatError(error);
        this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    deleteVideoInArray(videoId) {
      const index = this.adVideos.findIndex((video) => video.id === videoId);

      if (index !== -1) this.adVideos.splice(index, 1);
    },
    nextStep() {
      this.$emit("saved", false);
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  created() {
    this.getAdVideos();
  },
};
</script>

<style></style>
