<template>
  <div class="ad-images">
    <div class="text-center pt-5 pb-5" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-card v-else flat>
      <v-card-text>
        <v-row v-if="adImages.length" class="mt-2" justify="center" align="center">
          <v-sheet class="mx-auto" elevation="0" max-width="800">
            <v-slide-group v-model="selected" class="pa-4" show-arrows>
              <v-slide-item v-for="img in adImages" :key="img.id" v-slot="{ active, toggle }">
                <v-img class="ma-4" max-height="200" max-width="200" :src="getImageUrl(img.hash, img.image)" @click="toggle">
                  <v-row class="fill-height" align="center" justify="center">
                    <v-scale-transition>
                      <v-btn v-if="active" @click="deleteImage(img.id)" rounded>
                        <v-icon left v-text="'mdi-close-circle-outline'"></v-icon>
                        Remover
                      </v-btn>
                    </v-scale-transition>
                  </v-row>

                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-slide-item>
            </v-slide-group>

            <v-expand-transition>
              <v-sheet v-if="selected != null" tile>
                <v-row class="fill-height" align="center" justify="center">
                  <v-btn @click="showImage(selected)" text rounded>
                    <v-icon left v-text="'mdi-eye'"></v-icon>
                    Ver imagem
                  </v-btn>
                </v-row>
              </v-sheet>
            </v-expand-transition>
          </v-sheet>
        </v-row>

        <v-row v-else>
          <v-col>
            <v-alert border="top" colored-border type="info" elevation="2">
              Insira as imagens que aparecerão no seu anúncio
            </v-alert>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-file-input
              @change="uploadImage"
              ref="file"
              show-size
              label="Adicionar arquivo (s):"
              accept="image/*"
              :hint="persistentHint"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary darken-1" @click="saveImage()"> <v-icon left>mdi-content-save</v-icon> Salvar imagem</v-btn>

        <v-btn v-if="completeRegistration" class="primary darken-1" @click="nextStep()">
          <v-icon right>mdi-arrow-down-bold</v-icon> Avançar</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- modal de cortar a imagem -->
    <v-dialog v-model="cropDialog" hide-overlay persistent width="650">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Cortar imagem</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col>
              <Cropper class="cropper" ref="cropper" :src="image" :stencil-props="aspectRatio" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small @click="cropDialog = false"> <v-icon> mdi-close </v-icon> Cancelar </v-btn>
          <v-btn color="primary" small @click="applyCut()"> <v-icon> mdi-check </v-icon> Aplicar corte </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- modal de ver a imagem -->
    <v-dialog max-width="800" v-model="viewImageDialog">
      <v-card>
        <v-card-title>{{ imageName }}</v-card-title>
        <v-card-text>
          <v-img class="expanded-image" :src="viewImageSrc"></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="viewImageDialog = false"> <v-icon left>mdi-close</v-icon> Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { cloudUrl } from "@/config/config.json";

export default {
  components: {
    Cropper,
  },
  props: {
    completeRegistration: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    announcementId() {
      return this.$route.params.id;
    },
    aspectRatio() {
      return {
        aspectRatio: 16 / 9,
      };
    },
    persistentHint() {
      return !this.image ? "" : "Clique em Salvar Imagem abaixo!";
    },
  },
  data() {
    return {
      loading: false,
      selected: null, //informa a posição do item selecionado no slide
      viewImageDialog: false,
      cropDialog: false,
      viewImageSrc: null,
      imageName: null,
      image: null,
      originalName: null,
      adImages: [],
    };
  },
  methods: {
    async saveImage() {
      try {
        if (
          !(await this.$root.$confirm("Confirmação", "Salvar dados?", {
            color: "primary",
          }))
        )
          return;

        const errors = this.validateForm();

        if (errors.length) return this.$root.$errorDialog(errors);

        this.loading = true;

        let formData = new FormData();

        let imageContent = this.dataURLtoFile(this.image, this.originalName);

        formData.append("file", imageContent);

        await this.$axios.post(`/customers/posts/${this.announcementId}/images`, formData);

        this.getAdImages();

        this.image = null;
        this.originalName = null;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    validateForm() {
      let errors = [];

      if (!this.image) errors.push("Inclua a imagem desejada!");

      return errors;
    },
    uploadImage(file) {
      // Ensure that you have a file before attempting to read it
      if (file) {
        this.originalName = file.name;
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const MAX_WIDTH = 800;
            const MAX_HEIGHT = 400;
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }

            const elem = document.createElement("canvas");
            elem.width = width;
            elem.height = height;
            const ctx = elem.getContext("2d");

            ctx.drawImage(img, 0, 0, width, height);

            this.image = elem.toDataURL();
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(file);
        this.cropDialog = true;
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image
      this.image = canvas.toDataURL();
    },
    async applyCut() {
      try {
        if (
          !(await this.$root.$confirm("Atenção", "Aplicar corte?", {
            color: "primary",
          }))
        )
          return;

        this.cropDialog = false;

        this.crop();
      } catch (error) {
        this.handleErrors(error);
      }
    },
    async getAdImages() {
      try {
        this.loading = true;

        const response = await this.$axios.get(`/customers/posts/${this.announcementId}/images`);

        this.adImages = [...response.data];
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    getImageUrl(hash, image) {
      return `${cloudUrl}/${hash}${image}`;
    },
    async deleteImage(imageId) {
      try {
        if (
          !(await this.$root.$confirm("Atenção", "Deseja realmente excluir a imagem do anúncio?", {
            color: "primary",
          }))
        )
          return;

        this.loading = true;

        await this.$axios.delete(`/customers/posts/${this.announcementId}/images/${imageId}`);

        this.deleteImageInArray(imageId);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorHandled = errorHandler.treatError(error);
        this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    deleteImageInArray(imageId) {
      const index = this.adImages.findIndex((img) => img.id === imageId);

      if (index !== -1) this.adImages.splice(index, 1);
    },
    showImage(index) {
      const imageData = this.adImages[index];

      this.viewImageSrc = this.getImageUrl(imageData.hash, imageData.image);
      this.imageName = imageData.image;
      this.viewImageDialog = true;
    },
    nextStep() {
      this.$emit("saved", false);
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  created() {
    this.getAdImages();
  },
};
</script>

<style scoped>
.expanded-image {
  max-width: 800px;
  max-height: 800px;
  object-fit: scale-down;
}
</style>
