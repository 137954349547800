<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-card-title>Meus anúncios</v-card-title>

        <v-spacer></v-spacer>
        <v-btn small rounded color="success" @click="newAd">
          Cadastrar <v-icon right dark> mdi-plus </v-icon></v-btn
        >
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="id"
          show-expand
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-alert v-if="haveUnpostedAds" dense outlined type="error">
              Há anúncios desabilitados, complete ou revise o cadastro deles
              clicando em <b>Ações</b> e depois no botão
              <v-icon> mdi-power-plug </v-icon> para habilitá-los.
            </v-alert>
          </template>

          <template v-slot:[`item.expirationDate`]="{ item }">
            {{
              item.expirationDate
                ? $moment.utc(item.expirationDate).format("DD/MM/YYYY")
                : "-"
            }}
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{
              item.createdAt
                ? $moment.utc(item.createdAt).format("DD/MM/YYYY - HH:mm")
                : "-"
            }}
          </template>

          <template v-slot:[`item.adEnabled`]="{ item }">
            <v-icon color="green" v-if="item.enabled">
              mdi-check-circle
            </v-icon>

            <v-icon v-else color="red"> mdi-alert-circle </v-icon>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div v-if="!item.authorized">
                <v-alert class="mt-3" dense type="error">
                  Anúncio bloqueado pelo administrador do sistema. Entre em
                  contato para saber mais.
                </v-alert>
              </div>
              <div v-else>
                <v-row class="pt-2 pb-2">
                  <v-col sm="12">
                    <v-btn
                      v-if="item.enabled"
                      text
                      small
                      title="Configurar"
                      @click="openSettingsScreen(item.id)"
                    >
                      <v-icon class="pr-1"> mdi-cogs </v-icon> Configurar
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pt-2 pb-2">
                  <v-col sm="12">
                    <v-btn
                      text
                      small
                      :title="item.enabled ? 'Desabilitar' : 'Habilitar'"
                      @click="toggleEnabledFlag(item.id, !item.enabled)"
                    >
                      <v-icon class="pr-1">
                        {{
                          item.enabled ? "mdi-power-plug-off" : "mdi-power-plug"
                        }}
                      </v-icon>
                      {{ item.enabled ? "Desabilitar" : "Habilitar" }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pt-2 pb-2">
                  <v-col sm="12">
                    <v-btn
                      text
                      small
                      title="Excluir"
                      @click="deleteAnnouncement(item.id)"
                      :disabled="deletionIsDisabled(item)"
                    >
                      <v-icon class="pr-1"> mdi-delete </v-icon> Excluir
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { productionSiteURL } from "@/config/config.json";
import errorHandler from "@/helpers/error_handler";

export default {
  data() {
    return {
      resultsPerPage: 15,
      totalRows: 0,
      page: 1,
      search: null,
      haveUnpostedAds: false,
      loading: false,
      singleExpand: true,
      expanded: [],
      headers: [
        {
          text: "ID#",
          value: "id",
          sortable: true,
        },
        {
          text: "Plano",
          value: "planName",
          sortable: true,
        },
        {
          text: "Categoria",
          value: "categoryName",
          sortable: true,
        },
        {
          text: "Criado em",
          value: "createdAt",
          sortable: true,
        },
        { text: "Data de expiração", value: "expirationDate" },
        { text: "Habilitado", value: "adEnabled", sortable: false },
        { text: "Ações", value: "data-table-expand" },
      ],
      items: [],
    };
  },
  methods: {
    setAdsData(data) {
      const copyOfData = [...data];

      this.items = copyOfData.map((item) => {
        if (item.enabled === false) this.haveUnpostedAds = true;

        item.planName = item.Plan.name;

        item.categoryName = item.AdCategory ? item.AdCategory.name : "-";

        return item;
      });
    },
    async loadAdvertsData() {
      try {
        this.loading = true;

        let url = `/customers/posts`;
        if (this.search) url += `?search=${this.search}`;

        const response = await this.$axios.get(url);

        if (response.data.length) this.setAdsData(response.data);

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.handleErrors(error);
      }
    },
    deleteAnnouncementInArray(adId) {
      const index = this.items.findIndex((item) => item.id === adId);

      if (index !== -1) this.items.splice(index, 1);
    },
    async toggleEnabledFlag(announcementId, enabled) {
      try {
        this.loading = true;

        if (enabled)
          this.$router.push(`/anuncio/${announcementId}/completar-cadastro`);
        else {
          await this.$axios.put(`/customers/posts/${announcementId}/enabled`, {
            enabled: enabled,
          });
        }

        this.loadAdvertsData();
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async deleteAnnouncement(adId) {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Deseja realmente excluir o anúncio selecionado? Essa ação não incorrerá em descontos nas cobranças já efetuadas.",
            {
              color: "primary",
            }
          ))
        )
          return;

        this.loading = true;

        await this.$axios.delete(`/customers/posts/${adId}`);

        this.deleteAnnouncementInArray(adId);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorHandled = errorHandler.treatError(error);
        this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    deletionIsDisabled(adData) {
      const todayDate = this.$moment()
        .startOf("day")
        .add(3, "hours");
      const expirationDate = adData.expirationDate
        ? this.$moment.utc(adData.expirationDate).startOf("day")
        : null;

      return expirationDate && todayDate.isBefore(expirationDate);
    },
    clearFilters() {
      this.search = null;
      this.loadAdvertsData();
    },
    newAd() {
      try {
        window.open(`${productionSiteURL}/anuncie`, "_blank");
      } catch (error) {
        this.handleErrors(error);
      }
    },
    openSettingsScreen(announcementId) {
      this.$router.push(`/anuncio/${announcementId}/configurar`);
    },
    openCompleteScreen(announcementId) {
      this.$router.push(`/anuncio/${announcementId}/completar-cadastro`);
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  computed: {},
  mounted() {
    this.loadAdvertsData();
  },
};
</script>

<style></style>
