<template>
  <v-card :loading="loading">
    <v-toolbar flat>
      <v-app-bar-nav-icon
        ><v-btn title="voltar" icon @click="$router.go(-1)"
          ><v-icon>mdi-keyboard-backspace</v-icon></v-btn
        ></v-app-bar-nav-icon
      >
      <v-card-title>Completar/revisar cadastro de anúncio</v-card-title>
    </v-toolbar>

    <div class="text-center pt-5 pb-5" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <div v-else>
      <v-stepper vertical v-model="stepIndex">
        <v-stepper-step :complete="stepIndex > 1" step="1">
          Informações gerais
        </v-stepper-step>

        <v-stepper-content step="1">
          <AnnouncementForm
            :mod="'update'"
            :adData="adData"
            :adPhones="adPhones"
            :adFacilities="adFacilities"
            :completeRegistration="true"
            :dialog="true"
            @saved="nextAction"
          />
        </v-stepper-content>

        <v-stepper-step
          v-if="coverIsAllowed && logoIsAllowed"
          :complete="stepIndex > getStepNumber('logo')"
          :step="getStepNumber('logo')"
        >
          Capa e logo
        </v-stepper-step>

        <v-stepper-content
          v-if="coverIsAllowed && logoIsAllowed"
          :step="getStepNumber('logo')"
        >
          <CoverOrLogo :completeRegistration="true" @saved="nextAction" />
        </v-stepper-content>

        <v-stepper-step
          v-if="imagesIsAllowed"
          :complete="stepIndex > getStepNumber('adImages')"
          :step="getStepNumber('adImages')"
        >
          Fotos
        </v-stepper-step>

        <v-stepper-content
          v-if="imagesIsAllowed"
          :step="getStepNumber('adImages')"
        >
          <AdImages :completeRegistration="true" @saved="nextAction" />
        </v-stepper-content>

        <v-stepper-step
          v-if="videosIsAllowed"
          :complete="stepIndex > getStepNumber('adVideos')"
          :step="getStepNumber('adVideos')"
        >
          Vídeos
        </v-stepper-step>

        <v-stepper-content
          v-if="videosIsAllowed"
          :step="getStepNumber('adVideos')"
        >
          <AdVideos :completeRegistration="true" @saved="nextAction" />
        </v-stepper-content>

        <v-stepper-step
          v-if="downloadArchivesIsAllowed"
          :complete="stepIndex > getStepNumber('adFiles')"
          :step="getStepNumber('adFiles')"
        >
          Arquivos para download
        </v-stepper-step>

        <v-stepper-content
          v-if="downloadArchivesIsAllowed"
          :step="getStepNumber('adFiles')"
        >
          <AdFiles :completeRegistration="true" @saved="nextAction" />
        </v-stepper-content>

        <v-stepper-step
          v-if="openingHoursIsAllowed"
          :complete="stepIndex > getStepNumber('openingHours')"
          :step="getStepNumber('openingHours')"
        >
          Horários de funcionamento
        </v-stepper-step>

        <v-stepper-content
          v-if="openingHoursIsAllowed"
          :step="getStepNumber('openingHours')"
        >
          <OpeningHours
            :completeRegistration="true"
            :adOperatingHours="adOperatingHours"
            @saved="nextAction"
          />
        </v-stepper-content>

        <v-stepper-step
          v-if="paymentMethodsIsAllowed"
          :step="getStepNumber('paymentMethods')"
        >
          Formas de pagamento
        </v-stepper-step>

        <v-stepper-content
          v-if="paymentMethodsIsAllowed"
          :step="getStepNumber('paymentMethods')"
        >
          <PaymentMethods
            :completeRegistration="true"
            :adPaymentMethods="adPaymentMethods"
            @saved="nextAction"
          />
        </v-stepper-content>
      </v-stepper>
    </div>
  </v-card>
</template>

<script>
import AnnouncementForm from "@/components/Announcement/AnnouncementForm";
import OpeningHours from "@/components/Announcement/OpeningHours";
import AdImages from "@/components/Announcement/AdImages";
import AdVideos from "@/components/Announcement/AdVideos";
import AdFiles from "@/components/Announcement/AdFiles";
import PaymentMethods from "@/components/Announcement/PaymentMethods";
import CoverOrLogo from "@/components/Announcement/CoverOrLogo";
import errorHandler from "@/helpers/error_handler";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    AnnouncementForm,
    AdImages,
    AdVideos,
    AdFiles,
    OpeningHours,
    PaymentMethods,
    CoverOrLogo,
  },
  computed: {
    ...mapGetters("adverts", ["validateSpecificFeature"]),
    adId() {
      return this.$route.params.id;
    },
    coverIsAllowed() {
      return this.validateSpecificFeature(7);
    },
    logoIsAllowed() {
      return this.validateSpecificFeature(10);
    },
    videosIsAllowed() {
      return this.validateSpecificFeature(19);
    },
    downloadArchivesIsAllowed() {
      return this.validateSpecificFeature(18);
    },
    imagesIsAllowed() {
      return this.validateSpecificFeature(16);
    },
    openingHoursIsAllowed() {
      return this.validateSpecificFeature(14);
    },
    paymentMethodsIsAllowed() {
      return this.validateSpecificFeature(15);
    },
  },
  data() {
    return {
      stepIndex: 1,
      loading: false,
      createdCharge: null,
      adData: null,
      adPhones: [],
      adFacilities: [],
      adOperatingHours: [],
      adPaymentMethods: [],
      stepsData: [
        { featureId: 6, name: "logo" },
        { featureId: 16, name: "adImages" },
        { featureId: 19, name: "adVideos" },
        { featureId: 18, name: "adFiles" },
        { featureId: 14, name: "openingHours" },
        { featureId: 15, name: "paymentMethods" },
      ],
      planSteps: [],
    };
  },
  methods: {
    ...mapActions("adverts", ["setPlanFeatures"]),
    async checkAdvertiserRegistration() {
      const url = "customers/check-registration";

      const response = await this.$axios.get(url);

      const { needCompleteRegistration } = response.data;

      return needCompleteRegistration;
    },
    async init() {
      try {
        this.loading = true;

        const res = await this.$axios.get(`/customers/posts/${this.adId}`);

        const adData = { ...res.data };

        let needCompleteRegistration = false;

        if (adData.PlanId !== 1) {
          needCompleteRegistration = await this.checkAdvertiserRegistration();

          if (needCompleteRegistration) {
            this.$swal({
              icon: "error",
              title: "Cadastro incompleto",
              text: "Antes de configurar o seu anúncio, precisamos que você complete o seu cadastro",
              showCloseButton: false,
              allowOutsideClick: false,
            }).then(() => {
              this.$router.push({ path: "/usuarios/perfil" });
            });
          }
        }

        this.formatAndSetAdData(adData);
      } catch (error) {
       console.error(error);
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    formatAndSetAdData(data) {
      let copyOfData = { ...data };

      //se tentar completar o cadastro de um anuncio já publicado, redireciona para página de anuncios.
      if (copyOfData.enabled) this.$router.push(`/anuncios`);

      this.adPhones = copyOfData.AdPhones;
      delete copyOfData.CompanyPhones;

      this.adFacilities = copyOfData.Facilities.map((f) => {
        return f.id;
      });
      delete copyOfData.Facilities;

      this.adOperatingHours = copyOfData.OperatingHours;
      delete copyOfData.OperatingHours;

      this.adPaymentMethods = copyOfData.PaymentMethods;
      delete copyOfData.PaymentMethods;

      this.adData = copyOfData;

      this.setPlanFeatures(copyOfData.Plan.Features);
    },
    getStepNumber(name) {
      let index = this.planSteps.findIndex((s) => s.name === name);

      return index === -1 ? null : String(this.planSteps[index].step);
    },
    initSteps() {
      //o sempre haberá o primeiro passo que é o formulario do anuncio
      this.planSteps.push({ step: 1, featureId: 0, name: "announcementForm" });

      //verifica quais passos serão usados de acordo com o plano
      let includedInThePlan = false;
      let countSteps = 1;

      for (const step of this.stepsData) {
        //verifica se a feature Id está inclusa no plano
        includedInThePlan = this.validateSpecificFeature(step.featureId);

        if (includedInThePlan) {
          countSteps += 1; //atualiza o contador (começa em 1 pq o primeiro sempre vai ter)

          this.planSteps.push({
            step: countSteps,
            featureId: step.featureId,
            name: step.name,
          }); //inclui nos passos do plano

          includedInThePlan = false; //reseta a flag para o próximo teste
        }
      }
    },
    nextAction(lastStep) {
      this.stepIndex++;

      //se for o passo dos "métodos de pagamentos", que sempre será o último, ou se for plano Gratuiro
      // emitirá "true" em lastStep, então
      //salva anuncio como publicado e volta para página meus anuncios
      if (lastStep) this.saveAdAsPublished();
    },
    async saveAdAsPublished() {
      try {
        this.loading = true;

        const response = await this.$axios.put(
          `/customers/posts/${this.adId}/enabled`,
          {
            enabled: true,
          }
        );

        this.afterSave(!!response.data.createdCharge);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
        this.$router.push("/anuncios");
      }
    },
    afterSave(chargeHasCreated) {
      const text =
        this.adData.automaticBilling && chargeHasCreated
          ? "Uma cobrança foi criada para este anúncio. Efetue o pagamento para ativá-lo/reativá-lo."
          : "Anúncio atualizado e publicado com sucesso";

      const icon =
        this.adData.automaticBilling && chargeHasCreated
          ? "warning"
          : "success";

      const redirectTo =
        this.adData.automaticBilling && chargeHasCreated
          ? "/cobrancas"
          : "/anuncios";

      this.$swal({
        icon,
        title: "Dados salvos com sucesso",
        text,
        showCloseButton: false,
        allowOutsideClick: false,
      }).then(() => {
        this.$router.push(redirectTo);
      });
    },
    handleErrors(errors) {
     console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  mounted() {
    this.init();
  },
  beforeUpdate() {
    this.initSteps();
  },
};
</script>

<style></style>
