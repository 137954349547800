<template>
  <div>
    <v-sheet v-if="loading" color="grey lighten-4" class="pa-3">
      <v-skeleton-loader
        class="mx-auto"
        max-width="300"
        type="card"
      ></v-skeleton-loader>
    </v-sheet>

    <v-card v-else>
      <v-card-title>Minha empresa</v-card-title>

      <v-tabs v-model="tabIndex">
        <v-tab> Informações da empresa </v-tab>
        <v-tab>
          <v-icon v-if="havePendingRequests" left color="error"
            >mdi-alert-circle</v-icon
          >
          Avaliações
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabIndex">
        <v-tab-item><CompanyForm /></v-tab-item>
        <v-tab-item>
          <Ratings @no-pending-ratings="numberOfUnansweredReviews = 0" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import errorHandler from "@/helpers/error_handler";
import CompanyForm from "./CompanyForm";
import Ratings from "@/components/Company/Ratings";

export default {
  components: {
    CompanyForm,
    Ratings,
  },
  computed: {
    ...mapState("user", ["userData"]),
    havePendingRequests() {
      return this.numberOfUnansweredReviews > 0 ? true : false;
    },
  },
  data() {
    return {
      loading: false,
      tabIndex: null,
      numberOfUnansweredReviews: 0,
    };
  },
  methods: {
    async loadPendingRatings() {
      try {
        this.loading = true;

        let params = {
          reported: 0,
        };

        const response = await this.$axios.get(
          `/customers/companies/${this.userData.advertiser.Companies[0].id}/pending-ratings`,
          {
            params,
          }
        );

        const data = { ...response.data };

        if (data.ratingsWithoutAnswer && data.ratingsWithoutAnswer.length > 0)
          this.numberOfUnansweredReviews = data.ratingsWithoutAnswer.length;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  mounted() {
    this.loadPendingRatings();
  },
};
</script>

<style></style>
