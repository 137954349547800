<template>
  <BaseForm :title="title" :mod="mod" :flat="flat" :divider="divider" :dialog="dialog" :loading="loading" @save="save">
    <template #content>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-select class="required mb-2 mr-sm-2 mb-sm-0" :loading="loadingPlans" v-model="form.PlanId" :items="plans"
              label="Plano:" placeholder="Selecione o plano em que deseja anunciar" :disabled="mod === 'update'">
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select class="required" v-model="form.AdCategoryId" :items="adCategoryOptions"
              :loading="loadingAdCategories" label="Categoria"
              placeholder="Selecione a categoria em que deseja anunciar"></v-select>
          </v-col>
        </v-row>

        <v-row v-if="facilitiesIsAllowed || selectedFacilities.length">
          <v-col>
            <v-select class="required" v-model="selectedFacilities" :items="companyFacilitiesOptions"
              :loading="loadingFacilities" attach chips label="Facilidades" multiple></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field v-model="form.phone" label="Telefone principal" v-mask="'(##) #####-####'"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="form.whatsapp" label="Whatsapp" v-mask="'(##) #####-####'"
              prepend-icon="mdi-whatsapp"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" v-if="instagramIsAllowed">
            <v-text-field label="Instagram: " v-model="form.instagram" prepend-icon="mdi-instagram"
              placeholder="cole aqui o link do Instagram"></v-text-field>
          </v-col>
          <v-col cols="6" v-if="facebookIsAllowed">
            <v-text-field label="Facebook: " v-model="form.facebook" prepend-icon="mdi-facebook"
              placeholder="cole aqui o link do Facebook"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field v-model="form.site" label="Site"></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="informationIsAllowed">
          <v-col>
            <v-textarea v-model="form.description" label="Informações:"
              placeholder="Informe demais informações pertinentes ao seu anúncio" rows="3"></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h3>Endereço</h3>
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-checkbox v-model="useCompanyAddress" @change="handleCompanyAddressCheckbox"
              label="Utilizar o endereço da empresa cadastrado no sistema"></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="10">
            <v-text-field class="required" label="Logradouro" v-model="form.address" id="address" maxlength="254"
              :loading="loadingCompanyAddress"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field label="Número" v-model="form.number" id="address-number"
              :loading="loadingCompanyAddress"></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col cols="8">
            <v-text-field class="required" label="Bairro" v-model="form.district" id="district"
              :loading="loadingCompanyAddress"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field label="Complemento" v-model="form.complement" id="complement"
              :loading="loadingCompanyAddress"></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col cols="4">
            <v-text-field class="required" label="Cep:" v-model="form.zipCode" placeholder="xxxxx-xxx"
              v-mask="'#####-###'" :loading="loadingCompanyAddress"></v-text-field>
          </v-col>
          <v-col cols="8">
            <v-select class="required mb-2 mr-sm-2 mb-sm-0" :loading="loadingCities || loadingCompanyAddress"
              v-model="form.CityId" :items="cities" label="Cidade:" id="company-city">
            </v-select>
          </v-col>
        </v-row>

        <v-row v-if="coordinatesIsAllowed">
          <v-col>
            <v-text-field class="required" label="Coordenadas: " v-model="form.coordinate" prepend-icon="mdi-map-marker"
              @click:prepend="getPosition" placeholder="-xx.xxxxxx, -xx.xxxxxx"
              hint="Clique no ícone ao lado para pegar automaticamente" :loading="loadingCompanyAddress"></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col>
            <span>Coordenadas fornecidas pelo computador podem ser imprecisas.
              recomendamos que pegue automaticamente por um dispositivo móvel ou
              pelo maps.
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h3>Telefones secundários</h3>
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="12">
            <v-btn text small @click="addPhone()">
              <i class="fa fa-plus"></i> Adicionar telefone
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="mt-2 d-flex align-center" v-bind:key="index" v-for="(thisPhone, index) in phones">
          <v-col xs="10" sm="10" md="4">
            <v-text-field v-model="thisPhone.number" v-mask="'(##) #####-####'"></v-text-field>
          </v-col>
          <v-col xs="2" sm="2" md="2">
            <v-btn v-if="whatsAppIsAllowed" class="mr-2" icon small :color="thisPhone.whatsApp ? 'green' : 'grey'"
              title="Marcar/desmarcar como WhatsApp" @click="toggleWhatsAppFlag(index)">
              <v-icon> mdi-whatsapp </v-icon>
            </v-btn>
            <v-btn icon small title="Remover este telefone" @click="removePhone(thisPhone.id, index)">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #actions>
      <v-spacer></v-spacer>
      <v-btn v-if="completeRegistration" class="primary darken-1" @click="save('stay')">
        <v-icon right>{{ saveButtonIcon }}</v-icon> {{ saveButtonText }}
      </v-btn>
      <v-btn v-if="mod === 'update' && !completeRegistration" class="primary darken-1" @click="save('stay')">
        <v-icon left>mdi-content-save</v-icon> Salvar</v-btn>
    </template>
  </BaseForm>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";
import { mask } from "vue-the-mask";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    BaseForm,
  },
  mixins: [formMixin],
  directives: { mask },
  computed: {
    ...mapState("user", ["userData"]),
    ...mapGetters("adverts", ["validateSpecificFeature"]),
    announcementId() {
      return this.$route.params.id;
    },
    saveButtonText() {
      return this.form.PlanId === 1 ? "Salvar e habilitar" : "Salvar e avançar";
    },
    saveButtonIcon() {
      return this.form.PlanId === 1 ? "mdi-check" : "mdi-arrow-down-bold";
    },
    facilitiesIsAllowed() {
      return this.validateSpecificFeature(17);
    },
    instagramIsAllowed() {
      return this.validateSpecificFeature(12);
    },
    facebookIsAllowed() {
      return this.validateSpecificFeature(11);
    },
    informationIsAllowed() {
      return this.validateSpecificFeature(13);
    },
    coordinatesIsAllowed() {
      return this.validateSpecificFeature(6);
    },
    whatsAppIsAllowed() {
      return this.validateSpecificFeature(9);
    },
  },
  props: {
    adData: {
      type: Object,
    },
    adFacilities: {
      type: Array,
    },
    adPhones: {
      type: Array,
    },
    completeRegistration: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      loadingFacilities: false,
      loadingAdCategories: false,
      loadingCompanyAddress: false,
      loadingPlans: false,
      loadingCities: false,
      useCompanyAddress: false,
      adCategoryOptions: [],
      companyFacilitiesOptions: [],
      cities: [],
      plans: [],
      selectedFacilities: [],
      selectedCategories: [],
      phones: [],
      form: {
        coordinate: null,
      },
    };
  },
  methods: {
    async save(actionAfterOperation) {
      if (
        !(await this.$root.$confirm("Confirmação", "Salvar dados?", {
          color: "primary",
        }))
      )
        return;

      const errors = this.validateForm();

      if (errors.length) return this.$root.$errorDialog(errors);

      await this[this.mod](actionAfterOperation);
    },
    async insert(actionAfterOperation) {
      try {
        this.loading = true;

        let formattedData = await this.formatData();

        const response = await this.$axios.post(
          "/customers/posts",
          formattedData
        );

        this.afterInsertion(actionAfterOperation, {
          ...this.form,
          id: response.data.id,
        });
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        let formattedData = await this.formatData();

        await this.$axios.put(
          `/customers/posts/${this.announcementId}`,
          formattedData
        );

        //se for o PlanId 1 (gratuito), então é o último passo, então envia true para chamar a rota de habilitar o anúncio
        let isLastStep = this.form.PlanId === 1 ? true : false;

        this.$emit("saved", isLastStep);

        this.$toast.success("Dados salvos com sucesso");
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    formatData() {
      let auxForm = this.form;

      auxForm.useCompanyAddress = this.useCompanyAddress;

      auxForm.phones = this.phones;

      auxForm.facilities = this.selectedFacilities;

      return auxForm;
    },
    cleanCompanyAddress() {
      this.form.address = null;
      this.form.number = null;
      this.form.district = null;
      this.form.complement = null;
      this.form.zipCode = null;
      this.form.CityId = null;
      this.form.coordinate = null;
    },
    async getCompanyAddress() {
      const errors = [];

      const res = await this.$axios.get(
        `/customers/companies/${this.userData.advertiser.Companies[0].id}`
      );

      const data = { ...res.data };

      if (!data.address)
        errors.push(
          "Endereço não encontrado. Acesse o menu 'Minha empresa' e atualize o endereço para usar esta função"
        );

      if (errors.length) return errors;

      this.form.address = data.address;
      this.form.number = data.number;
      this.form.district = data.district;
      this.form.complement = data.complement;
      this.form.zipCode = data.zipCode;
      this.form.CityId = data.CityId;
      this.form.coordinate = data.coordinate
        ? this.parseCoorinate(data.coordinate)
        : null;

      return [];
    },
    async handleCompanyAddressCheckbox(event) {
      try {
        if (event === true) {
          this.loadingCompanyAddress = true;

          const errors = await this.getCompanyAddress();

          if (errors.length) return this.$root.$errorDialog(errors);
        } else {
          this.cleanCompanyAddress();
        }
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loadingCompanyAddress = false;
      }
    },
    validateForm() {
      const errors = [];

      if (!this.form.AdCategoryId)
        errors.push("Categoria do anúncio obrigatória");

      if (!this.form.PlanId) errors.push("Plano do anúncio obrigatório");

      if (!this.selectedFacilities.length && this.form.PlanId === 4)
        errors.push("Informe ao menos uma facilidade");

      if (!this.form.address) errors.push("Logradouro obrigatório");

      if (!this.form.district) errors.push("Bairro obrigatório");

      if (!this.form.zipCode) errors.push("CEP obrigatório");

      if (!this.form.phone) errors.push("Informe ao menos o telefone principal");

      if (!this.form.CityId) errors.push("Cidade obrigatória");

      if (!this.form.coordinate && this.form.PlanId !== 1)
        errors.push("Coordenada obrigatório");

      return errors;
    },
    async getFacilities() {
      try {
        this.loadingFacilities = true;

        const res = await this.$axios.get("/facilities?all=1");

        this.setFacilitiesData(res.data);

        this.loadingFacilities = false;
      } catch (error) {
        this.loadingFacilities = false;
        this.handleErrors(error);
      }
    },
    setFacilitiesData(facilities) {
      this.companyFacilitiesOptions = facilities.map((f) => {
        return {
          value: f.id,
          text: f.name,
        };
      });
    },
    async getCities() {
      try {
        this.loadingCities = true;

        const res = await this.$axios.get("/cities");

        this.setCitiesData(res.data);

        this.loadingCities = false;
      } catch (error) {
        this.loadingCities = false;
        this.handleErrors(error);
      }
    },
    setCitiesData(cities) {
      this.cities = cities.map((c) => {
        return {
          value: c.id,
          text: `${c.name} - ${c.uf}`,
        };
      });
    },
    async getAdCategories() {
      try {
        this.loadingAdCategories = true;

        const res = await this.$axios.get("/customers/posts/categories");

        this.setCategoriesData(res.data);

        this.loadingAdCategories = false;
      } catch (error) {
        this.loadingAdCategories = false;
        this.handleErrors(error);
      }
    },
    setCategoriesData(categories) {
      this.adCategoryOptions = categories.map((c) => {
        return {
          value: c.id,
          text: c.name,
        };
      });
    },
    async getPlans() {
      try {
        this.loadingPlans = true;

        const res = await this.$axios.get("/plans");

        this.setPlansData(res.data);

        this.loadingPlans = false;
      } catch (error) {
        this.loadingPlans = false;
        this.handleErrors(error);
      }
    },
    setPlansData(plans) {
      this.plans = plans.map((p) => {
        return {
          value: p.id,
          text: `${p.name} (R$ ${parseFloat(p.value)
            .toFixed(2)
            .replace(".", ",")}/mês)`,
        };
      });
    },
    getPosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.form.coordinate = `${position.coords.latitude}, ${position.coords.longitude}`;
          },
          () => {
            this.handleErrors("Erro ao pegar localização");
          }
        );
      } else {
        this.handleErrors("Geolocalização não suportada pelo navegador");
        return;
      }
    },
    addPhone() {
      this.phones.push({
        number: null,
        whatsApp: false,
      });
    },
    removePhone(id, index) {
      if (id) {
        this.removePhoneFromApi(id);
      } else {
        this.removePhoneFromArrayByIndex(index);
      }
    },
    removePhoneFromArrayByIndex(index) {
      this.phones.splice(index, 1);
    },
    removePhoneFromArrayById(id) {
      //procura o indice passado como parametro no array de respostas
      let indexFound = this.phones.findIndex((p) => p.id === id);

      //realiza a remoção
      if (indexFound !== -1) this.phones.splice(indexFound, 1);
    },
    async removePhoneFromApi(id) {
      try {
        this.loading = true;

        await this.$axios.delete(
          `/customers/posts/${this.announcementId}/phones/${id}`
        );

        this.removePhoneFromArrayById(id);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    toggleWhatsAppFlag(index) {
      this.phones[index].whatsApp = !this.phones[index].whatsApp;
    },
    setAnnouncementData() {
      if (this.adData.coordinate)
        this.adData.coordinate = this.parseCoorinate(this.adData.coordinate);
      this.form = { ...this.adData };
      this.selectedFacilities = this.adFacilities;
      this.phones = this.adPhones;
    },
    parseCoorinate(coordinateObject) {
      return `${coordinateObject.coordinates[1]},${coordinateObject.coordinates[0]}`;
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  watch: {
    adData(value) {
      if (value && this.mod === "update") this.setAnnouncementData(value);
    },
  },
  created() {
    this.getFacilities();
    this.getCities();
    this.getAdCategories();
    this.getPlans();
  },
  mounted() {
    if (this.mod === "update" && this.adData) this.setAnnouncementData();
  },
};
</script>

<style></style>
