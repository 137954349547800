<template>
  <div class="personalization">
    <div class="text-center pt-5 pb-5" v-if="isBusy">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-card v-else flat>
      <v-card-text>
        <v-row class="my-3">
          <v-col md="4">
            <v-img :src="srcLogoImage" max-width="200"></v-img>
          </v-col>
          <v-col md="4">
            <v-file-input
              ref="file"
              @change="uploadImage"
              accept="image/*"
              prepend-icon="mdi-camera"
              label="Incluir arquivo para alterar logo"
            ></v-file-input>

            <v-btn color="success" small @click="saveLogo()">
              <v-icon left>mdi-check</v-icon> Salvar logo
            </v-btn>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row class="my-3">
          <v-col md="4">
            <v-img :src="srcCoverImage" max-width="200"></v-img>
          </v-col>
          <v-col md="4">
            <v-file-input
              ref="file"
              @change="uploadImage"
              accept="image/*"
              prepend-icon="mdi-camera"
              label="Incluir arquivo para alterar capa"
            ></v-file-input>

            <v-btn color="success" small @click="saveCover()">
              <v-icon left>mdi-check</v-icon> Salvar capa
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions v-if="completeRegistration">
        <v-spacer></v-spacer>
        <v-btn class="primary darken-1" @click="nextStep()">
          <v-icon right>{{ saveButtonIcon }}</v-icon>
          {{ saveButtonText }}</v-btn
        >
      </v-card-actions>

      <!-- modal de cortar a imagem -->
      <v-dialog v-model="cropDialog" hide-overlay persistent width="650">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>Cortar imagem</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col>
                <Cropper
                  class="cropper"
                  ref="cropper"
                  :src="image"
                  :stencil-props="aspectRatio"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text small @click="cropDialog = false">
              <v-icon> mdi-close </v-icon> Cancelar
            </v-btn>
            <v-btn color="primary" small @click="applyCut()">
              <v-icon> mdi-check </v-icon> Aplicar corte
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import noImageLogo from "@/assets/default-logo.jpg";
import noImageCover from "@/assets/default-cover.png";
import { cloudUrl } from "@/config/config.json";

export default {
  components: {
    Cropper,
  },
  computed: {
    announcementId() {
      return this.$route.params.id;
    },
    aspectRatio() {
      return {
        aspectRatio: 16 / 9,
      };
    },
    saveButtonText() {
      return this.announcementInfo.PlanId === 2
        ? "Salvar e habilitar"
        : "Avançar";
    },
    saveButtonIcon() {
      return this.announcementInfo.PlanId === 2
        ? "mdi-check"
        : "mdi-arrow-down-bold";
    },
  },
  props: {
    completeRegistration: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBusy: false,
      image: null,
      cropDialog: null,
      originalName: null,
      logoContent: null,
      coverContent: null,
      srcLogoImage: noImageLogo,
      srcCoverImage: noImageCover,
      announcementInfo: {},
    };
  },
  methods: {
    async loadPersonalizationData() {
      try {
        this.isBusy = true;

        const res = await this.$axios.get(
          `/customers/posts/${this.announcementId}`
        );

        this.setPersonalizationData(res.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.isBusy = false;
      }
    },
    setPersonalizationData(data) {
      const copyOfData = { ...data };

      if (copyOfData.logo !== null) {
        this.srcLogoImage = `${cloudUrl}/${copyOfData.logo}`;
      }

      if (copyOfData.cover !== null)
        this.srcCoverImage = `${cloudUrl}/${copyOfData.cover}`;

      this.announcementInfo = copyOfData;
    },
    uploadImage(file) {
      // Ensure that you have a file before attempting to read it
      if (file) {
        this.originalName = file.name;
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const MAX_WIDTH = 800;
            const MAX_HEIGHT = 400;
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }

            const elem = document.createElement("canvas");
            elem.width = width;
            elem.height = height;
            const ctx = elem.getContext("2d");

            ctx.drawImage(img, 0, 0, width, height);

            this.image = elem.toDataURL();
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(file);
        this.cropDialog = true;
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image
      this.image = canvas.toDataURL();
    },
    async saveLogo() {
      const errors = this.validate();

      if (errors.length) return this.$root.$errorDialog(errors);

      if (
        !(await this.$root.$confirm(
          "Atenção",
          "Deseja alterar para esta imagem?",
          {
            color: "primary",
          }
        ))
      )
        return;

      this.isBusy = true;

      const formData = new FormData();

      this.logoContent = this.dataURLtoFile(this.image, this.originalName);
      formData.append("file", this.logoContent);

      this.save("logo", formData);
    },
    nextStep() {
      //se for o PlanId 3 (iniciante), então é o último passo, então envia true para chamar a rota de habilitar o anúncio
      let isLastStep = this.announcementInfo.PlanId === 2 ? true : false;

      this.$emit("saved", isLastStep);
    },
    async saveCover() {
      const errors = this.validate();

      if (errors.length) return this.$root.$errorDialog(errors);

      if (
        !(await this.$root.$confirm(
          "Atenção",
          "Deseja alterar para esta imagem?",
          {
            color: "primary",
          }
        ))
      )
        return;

      this.isBusy = true;

      const formData = new FormData();

      this.coverContent = this.dataURLtoFile(this.image, this.originalName);
      formData.append("file", this.coverContent);

      this.save("cover", formData);
    },
    async save(type, formData) {
      try {
        /** Fazendo upload da nova imagem */
        await this.$axios.put(
          `/customers/posts/${this.announcementId}/files?type=${type}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.loadPersonalizationData();
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.image = null;
        this.isBusy = false;
      }
    },
    validate() {
      let errors = [];

      if (!this.image) errors.push("Informe o arquivo desejado!");

      return errors;
    },
    async applyCut() {
      try {
        if (
          !(await this.$root.$confirm("Atenção", "Aplicar corte?", {
            color: "primary",
          }))
        )
          return;

        this.cropDialog = false;

        this.crop();
      } catch (error) {
        this.handleErrors(error);
      }
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  mounted() {
    this.loadPersonalizationData();
  },
};
</script>

<style></style>
